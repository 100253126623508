export default class PropUpdateForm {
  constructor(data) {
    this.name = data?.name
    this.sortWeight = data?.sortWeight
    this.unitOfMeasureId = data?.unitOfMeasureId
  }
  static create(data) {
    return new PropUpdateForm(data)
  }
}
