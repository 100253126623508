<template>
  <div>
    <h1 class="text-h4">Управление параметрами номенклатурны</h1>
    <v-divider class="my-2"></v-divider>
    <v-form v-model="form.valid" :disabled="busy" @submit.prevent="submitForm">
      <v-card>
        <v-card-title>Редактирование свойства номенклатуры {{ id | shortenId }}</v-card-title>
        <v-card-text>
          <v-text-field
            v-model="form.data.name"
            label="Название"
            counter="150"
            :rules="form.rule.name"
          />
          <v-autocomplete
            v-model="form.data.unitOfMeasure"
            dense
            label="Единица измерения"
            item-text="name"
            item-value="id"
            :items="unitOfMeasures"
            clearable
            return-object
          />
          <v-text-field
            v-model="form.data.sortWeight"
            label="Вес сортировки"
            counter="7"
            :rules="form.rule.sortWeight"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :to="{name: 'props.list'}">Отмена</v-btn>
          <v-btn color="primary" :disabled="!form.valid" type="submit">Отправить</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import { dashboardPageCommonAttributes } from '@/mixins/DashboardPageCommonAttributes'
import { ruleNumeric, ruleRequired } from '@/utils/FormRules'
import { mapActions, mapState } from 'vuex'
import PropUpdateForm from '@/model/props/PropUpdateForm'

export default {
  ...dashboardPageCommonAttributes,
  name: 'PropsEditPage',
  props: {
    id: {
      type: [String],
      required: true
    }
  },
  data() {
    return {
      form: {
        valid: true,
        data: {
          name: null,
          unitOfMeasure: null,
          sortWeight: 10
        },
        rule: {
          name: [ruleRequired()],
          sortWeight: [ruleNumeric()]
        }
      }
    }
  },
  computed: {
    ...mapState('props_edit', ['item', 'busy','unitOfMeasures'])
  },
  created() {
    this.fetchData({ id: this.id })
      .then(() => {
        this.form.data.name = this.item.name
        if (this.item.unitOfMeasure && this.item.unitOfMeasure.id) {
          this.form.data.unitOfMeasure = this.unitOfMeasures.find((u) => u.id === this.item.unitOfMeasure.id)
        }
      })
  },
  methods: {
    ...mapActions('props_edit', ['fetchData', 'updateProp']),
    submitForm() {
      const form = PropUpdateForm.create({
        ...this.form.data,
        unitOfMeasureId: this.form.data.unitOfMeasure?.id
      })

      this.updateProp({ form })
        .then(() => this.$router.push({ name: 'props.list' }))
    }
  }
}
</script>
